import * as middleware from 'app/blocks/middleware/middleware';
import { REST } from './utils';

const PROFILE_AFFILITIONS_LIMIT = 20;

const { GET, POST } = REST('/userprofile');

export const getAlerts = (): Promise<{ alerts: Alert[]; emails: string[]; notificationEmail: string }> =>
    GET('/alerts/');

export const saveAlerts = (alerts): Promise<boolean> => POST('/alerts/', alerts);

export const getProfileInformation = async (): Promise<Profile> => {
    const response = await GET('/profileInfo/');
    const appinfo = await middleware.appinfo.get();

    response.profileRedirectionURL = appinfo.properties['extsvc.url.profileUrl'];
    return response;
};

export const getAffiliations = async (): Promise<Affiliation[]> => {
    const affiliations = await GET('/affiliations/');
    return (affiliations || []).slice(0, PROFILE_AFFILITIONS_LIMIT);
};
